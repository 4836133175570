<template>
  <div class="cart__icon no-line-height" @click="open">
    <CartIcon />
    <span class="cart__badge" v-if="!isOnCartPage && totalCartItems > 0">{{
      totalCartItems
    }}</span>
  </div>

  <client-only v-if="!isOnCartPage" :is-show="componentStore.isShowCartPopup">
    <Popup
      class="cart-popup"
      v-model:show="showing"
      width="semi-full"
      transition="slide-left"
      ref="popup"
    >
      <div class="cart-popup__header">
        <h2>Shopping cart</h2>
        <span @click="close" class="icon-wrapper">
          <CloseIcon class="cart-popup__close-icon" />
        </span>
      </div>
      <div class="cart-popup__body">
        <Cart
          :isFullPage="false"
          ref="cart"
          @link-clicked="close"
          :isUseWrapper="true"
          :wrapper="popup?.content"
        />
      </div>
    </Popup>
  </client-only>
</template>

<script lang="ts" setup>
import EventBus from '@/services/eventbus'
import useCheckoutStore from '@/store/checkout'
import useComponentStore from '@/store/component'
import {
  CAMPAIGN_DISCOUNT_LINE_ITEM_ATTRIBUTE_KEY,
  CURRENCY,
  EVENT_ADD_TO_CART,
  EVENT_TRACKING_CART_VIEWED,
  LINE_ITEM_PREVIEW_IMAGE_ATTRIBUTE,
} from '@/utils'
import { runTaskOnIdleTime } from '@/utils/idlize'
import {
  computed,
  onMounted,
  onUnmounted,
  Ref,
  ref,
  watch,
  WatchStopHandle,
} from 'vue'
import { useRoute } from 'vue-router'
import CartIcon from '@/assets/images/icons/cart.svg'
import CloseIcon from '@/assets/images/icons/icon-close.svg'
import Cart from './Cart.vue'
import Popup from '@/ui-kits/Popup.vue'
import Analytics from '@/services/analytics'
import {
  buildProductUrl,
  getRawIdFromShopifyId,
  standardizeCartIdFromShopifyId,
} from '@/utils/product'
import useDeviceStore from '@/store/device'
import { useQrGreetingCardAndDigitalContent } from '@/pages/product/composables/useQrGreetingCardAndDigitalContent'

const { checkQrGreetingaCardAndDigitalContent } =
  useQrGreetingCardAndDigitalContent()
const componentStore = useComponentStore()
const checkoutStore = useCheckoutStore()
const deviceStore = useDeviceStore()
const route = useRoute()

const showing = ref(false)
const popup: Ref<typeof Popup | null> = ref(null)
const cart = ref<typeof Cart | null>(null)

const isOnCartPage = computed(() => {
  return route.name == 'cart' || route.name == 'cart-recovery'
})

const totalCartItems = computed(() => {
  const lineItemsQrGreetingCardAndDigitalContent =
    checkQrGreetingaCardAndDigitalContent()
  let totalItemHidden = 0
  if (lineItemsQrGreetingCardAndDigitalContent.length) {
    totalItemHidden = lineItemsQrGreetingCardAndDigitalContent.filter(
      (item: any) => item.isHidden
    ).length
  }
  return (checkoutStore.totalCartItems || 0) - totalItemHidden
})

onMounted(async () => {
  EventBus.on(EVENT_ADD_TO_CART, open)

  let unWatch: WatchStopHandle | null = null
  unWatch = watch(
    () => deviceStore.firstInputFired || deviceStore.preloadImageLoaded,
    (value) => {
      if (value) {
        runTaskOnIdleTime(() => {
          componentStore.isShowCartPopup = true
        })
        setTimeout(() => {
          if (unWatch) {
            unWatch()
          }
        })
      }
    },
    { immediate: true }
  )
})

onUnmounted(() => {
  EventBus.off(EVENT_ADD_TO_CART, open)
})

function close() {
  showing.value = false
}

function open() {
  if (isOnCartPage.value) return
  showing.value = true
  if (cart.value) {
    cart.value.checkRefreshCart()
  }
  const products = checkoutStore.localCartLineItems.map((lineItem) => {
    const product = lineItem.variant.product
    return {
      product_id: lineItem.variant.sku,
      sku: lineItem.variant.sku,
      name: product?.title,
      variant: getRawIdFromShopifyId(lineItem.variant.id),
      price: lineItem.variant.price,
      quantity: lineItem.quantity,
      handle: product?.handle,
      url: window.location.origin + buildProductUrl(product?.handle || ''),
      image_url: product?.featuredImage?.src,
      type: product?.productType,
      tags: product?.tags,
      collections: product?.collections?.map((collection) => collection.title),
      price_discount: lineItem.compareAtPrice ? lineItem.price : undefined,
      compare_price_discount: lineItem.compareAtPrice || undefined,
      preview_image_url:
        lineItem.customAttributes.find(
          (elm) => elm.key == LINE_ITEM_PREVIEW_IMAGE_ATTRIBUTE
        )?.value || lineItem.variant.image?.src,
      campaign_discount: lineItem.customAttributes.find(
        (elm) => elm.key == CAMPAIGN_DISCOUNT_LINE_ITEM_ATTRIBUTE_KEY
      )?.value,
    }
  })
  Analytics.track(EVENT_TRACKING_CART_VIEWED, {
    cart_id: standardizeCartIdFromShopifyId(checkoutStore.checkout?.id),
    cart_type: 'popup',
    currency: CURRENCY,
    products,
  })
}
</script>

<style lang="scss">
.cart {
  &__icon {
    cursor: pointer;
    position: relative;
    justify-self: center;
    width: 22px;

    svg {
      width: 22px;
    }
  }

  &__badge {
    position: absolute;
    top: -8px;
    right: -8px;
    width: 15px;
    height: 15px;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-primary);
    color: #fff;
    border-radius: 50%;
  }
}

.cart-popup {
  $S: &;

  .popup__content {
    max-width: 500px !important;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px var(--border-color);
    position: sticky;
    top: 0;
    background-color: #fff;

    h2 {
      font-size: 20px;
      margin: 0.75em 0 0.5em;
    }

    #{$S}__close-icon {
      width: 1em;
      height: 1em;
    }
  }

  @include media-desktop {
    .right-side-popup__content {
      padding: 0 2em;
      display: grid;
      grid-template-rows: max-content 1fr;
    }

    &__body {
      height: 100%;
    }

    .cart {
      &__items {
        min-height: 50%;
      }

      &__subtotal-container {
        position: sticky;
        bottom: 0;
        background-color: #fff;
      }

      &__trust-image,
      &__trust-item {
        display: none;
      }
    }
  }
}
</style>
