import { UserSegment } from '@/types/user'
import { defineStore } from 'pinia'
import useSettingStore from '../setting'
import { CookieManager } from '@/services/cookie'
import Provider from '@/provider'
import {
  CACHE_KEY_CHECKOUT_ID,
  COOKIE_KEY_KLAVIYO_EMAIL,
  COOKIE_KEY_RUDDER_USER_ID,
  EVENT_USER_SUBSCRIBE,
  ONE_YEAR_IN_SECONDS,
  TIKTOK_EXTERNAL_ID_TYPE,
  removePropertiesStartWithPrefix,
} from '@/utils'
import Analytics from '@/services/analytics'
import Logger from '@/services/log'

export interface UserState {
  segments: Array<UserSegment>
  userEmailKlaviyo: string | null
}

const useUserStore = defineStore('user', {
  state: (): UserState => ({
    segments: [],
    userEmailKlaviyo: null,
  }),
  getters: {
    userBlockedProductTags() {
      const userStore = useUserStore()
      const settingStore = useSettingStore()
      const defaultBlockedProductTags =
        settingStore.userSegments?.defaultExcludedProductTags
      if (!defaultBlockedProductTags) return []

      let blockedProductTags = defaultBlockedProductTags
      if (!userStore.segments || !userStore.segments.length)
        return blockedProductTags

      userStore.segments.forEach((segment) => {
        // filter out product tags that unblocked by segment
        if (segment.unlockProductTags && segment.unlockProductTags.length) {
          blockedProductTags = blockedProductTags.filter(
            (blockedTag) => !segment.unlockProductTags?.includes(blockedTag)
          )
        }
      })
      return blockedProductTags
    },
    userIdIdentified() {
      const cookier = new CookieManager()
      const userIdFromCookie = cookier.getCookie(COOKIE_KEY_RUDDER_USER_ID)
      return userIdFromCookie
    },
    userEmailKlaviyoIdentified() {
      const cookier = new CookieManager()
      const userEmailFromCookie = cookier.getCookie(COOKIE_KEY_KLAVIYO_EMAIL)
      return userEmailFromCookie
    },
  },

  actions: {
    async getUserIdentified(ip?: string, email?: string) {
      const provider = await Provider.getInstance()
      const response = await provider.identifyUser(ip, email)

      if (!response?.id) return

      const cookier = new CookieManager()
      const href = new URL(window.location.href)
      const currentDomain = href.hostname
      cookier.setCookie(COOKIE_KEY_RUDDER_USER_ID, response?.id, {
        maxage: ONE_YEAR_IN_SECONDS,
        domain: '.' + currentDomain,
        path: '/',
      })

      return response
    },

    async identifyUserByEmail(email: string, ip: string) {
      const userTraits = Analytics.getUserTraits()
      const listEmail = userTraits?.listEmail || []
      if (!listEmail?.length || !listEmail.includes(email)) {
        listEmail.push(email)
      }
      let userEmail = { email, listEmail }

      let userIdIdentified = this.userIdIdentified // get user id identified in cache
      let traits = {}
      if (!userIdIdentified) {
        try {
          const user = await this.getUserIdentified(ip, email) // check user identified
          if (!user?.id) {
            Analytics.identify(userEmail)
            return
          }
          userIdIdentified = user.id
          if (user?.traits) {
            traits = removePropertiesStartWithPrefix(user.traits, '$')
          }
          if (user?.traits?.listEmail?.length) {
            let listEmailMerged = user.traits.listEmail || []
            listEmail.forEach((email: string) => {
              if (!listEmailMerged.includes(email)) {
                listEmailMerged.push(email)
              }
            })
            listEmailMerged = listEmailMerged.slice(-5) // get the 5 newest email
            userEmail = {
              email,
              listEmail: listEmailMerged,
            }
          }
        } catch (error: any) {
          Analytics.identify(userEmail)
          Analytics.error(error)
          Logger.error('Error on SIB api identify user', { error })
        }
      }

      Analytics.identify(
        userIdIdentified,
        {
          ...traits,
          ...userEmail,
        },
        {
          externalld: [
            {
              id: userIdIdentified,
              type: TIKTOK_EXTERNAL_ID_TYPE,
            },
          ],
        }
      )
      Analytics.track(EVENT_USER_SUBSCRIBE, {
        ...traits,
        ...userEmail,
      })
    },

    async getUserKlaviyoByExchangeId(exchangeId: string, store: string) {
      try {
        const provider = await Provider.getInstance()
        const response = await provider.getUserKlaviyoByExchangeId(
          exchangeId,
          store
        )
        if (!response) return

        let user = {}
        const cookier = new CookieManager()
        const href = new URL(window.location.href)
        const currentDomain = href.hostname
        if (response.email) {
          user = { ...user, email: response.email }
          this.userEmailKlaviyo = response.email
          cookier.setCookie(COOKIE_KEY_KLAVIYO_EMAIL, response.email, {
            maxage: ONE_YEAR_IN_SECONDS,
            domain: '.' + currentDomain,
            path: '/',
          })
        }
        if (response.checkoutId) {
          user = { ...user, checkoutId: response.checkoutId }

          cookier.setCookie(CACHE_KEY_CHECKOUT_ID, response.checkoutId, {
            maxage: ONE_YEAR_IN_SECONDS,
            domain: '.' + currentDomain,
            path: '/',
          })
        }
        if (response.phone) user = { ...user, phone: response.phone }
        if (response.last_name) user = { ...user, lastName: response.last_name }
        if (response.first_name)
          user = { ...user, firstName: response.first_name }

        return user
      } catch (error: any) {
        Analytics.error(error)
        Logger.error('Error on SIB api get user klaviyo by exchangeId', {
          error,
        })
      }
    },
  },
})

export default useUserStore
