<template>
  <footer class="container footer" ref="footerElement" :id="footerId">
    <div class="divider mt-lg mb-lg"></div>
    <div class="footer__col">
      <Logo class="mb-md" @click="trackingClickLogo" />
      <address
        class="footer__address mb-md"
        v-if="footerSetting && footerSetting.address"
      >
        <div
          class="item"
          v-for="(address, index) of footerSetting.address"
          :key="index"
        >
          <span class="text--bold">{{ address.country }}: &nbsp;</span>
          <span>{{ address.address }}</span>
        </div>
      </address>
      <address
        class="footer__contact"
        v-if="footerSetting && footerSetting.contact"
      >
        <div class="item" v-if="footerSetting.contact.email">
          <span>Email: &nbsp;</span>
          <a :href="'mailto:' + footerSetting.contact.email">{{
            footerSetting.contact.email
          }}</a>
        </div>
        <div class="item" v-if="footerSetting.contact.tel">
          <span>Phone: &nbsp;</span>
          <a :href="'tel:' + footerSetting.contact.tel">{{
            footerSetting.contact.tel
          }}</a>
        </div>
      </address>
      <client-only
        v-if="trustpilotSettings?.isShow"
        :is-show="isShowTrustpilot"
      >
        <TrustPilot
          :template-id="trustpilotSettings.templateId"
          :business-unit-id="trustpilotSettings.businessUnitId"
          :min-review-count="trustpilotSettings.minReviewCount"
          :url="trustpilotSettings.url"
        ></TrustPilot>
      </client-only>
    </div>

    <client-only :is-show="componentStore.isShowFooterLink">
      <div class="footer__col" v-if="footerSetting && footerSetting.links">
        <div
          class="footer__link"
          v-for="(link, index) of footerSetting.links"
          :key="index"
        >
          <h3>{{ link.label }}</h3>
          <ul>
            <li class="item" v-for="(menu, index) of link.menu" :key="index">
              <sib-link :to="menu.url" :title="menu.title">
                {{ menu.title }}
              </sib-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer__col">
        <div class="footer__payments">
          <h3>We Accept</h3>
          <ul>
            <li>
              <ShopPayIcon />
            </li>
            <li>
              <VisaIcon />
            </li>
            <li>
              <MasterCardIcon />
            </li>
            <li>
              <AmericanExpressIcon />
            </li>
            <li>
              <PaypalIcon />
            </li>
          </ul>
        </div>
        <div
          class="footer__socials"
          v-if="footerSetting && footerSetting.social"
        >
          <h3>Follow Us</h3>
          <ul>
            <li v-if="footerSetting.social.tiktok">
              <a :href="footerSetting.social.tiktok">
                <TikTokIcon />
              </a>
            </li>
            <li v-if="footerSetting.social.instagram">
              <a :href="footerSetting.social.instagram">
                <InstagramIcon />
              </a>
            </li>
            <li v-if="footerSetting.social.facebook">
              <a :href="footerSetting.social.facebook">
                <FacebookIcon />
              </a>
            </li>
            <li v-if="footerSetting.social.twitter">
              <a :href="footerSetting.social.twitter">
                <TwitterIcon />
              </a>
            </li>
            <li v-if="footerSetting.social.youtube">
              <a :href="footerSetting.social.youtube">
                <YoutubeIcon />
              </a>
            </li>
            <li v-if="footerSetting.social.pinterest">
              <a :href="footerSetting.social.pinterest">
                <PinterestIcon />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <p class="text--bold mt-md">{{ copyRightText }}</p>
    </client-only>
  </footer>
</template>

<script setup lang="ts">
import { SettingPage } from '@/types'
import { Ref, computed, onMounted, ref } from 'vue'
import useSettingStore from '@/store/setting'
import Logo from '@/components/header/Logo.vue'
import ShopPayIcon from '@/assets/images/icons/shop-pay.svg'
import VisaIcon from '@/assets/images/icons/visa.svg'
import MasterCardIcon from '@/assets/images/icons/master-card.svg'
import PaypalIcon from '@/assets/images/icons/paypal.svg'
import AmericanExpressIcon from '@/assets/images/icons/american-expess.svg'
import FacebookIcon from '@/assets/images/icons/facebook.svg'
import TikTokIcon from '@/assets/images/icons/tiktok.svg'
import InstagramIcon from '@/assets/images/icons/instagram.svg'
import TwitterIcon from '@/assets/images/icons/twitter.svg'
import YoutubeIcon from '@/assets/images/icons/youtube.svg'
import PinterestIcon from '@/assets/images/icons/pinterest.svg'
import useComponentStore from '@/store/component'
import { runTaskOnIdleTime } from '@/utils/idlize'
import TrustPilot from './Trustpilot.vue'
import {
  EVENT_TRACKING_CLICK_LOGO,
  MIXPANEL_DESTINATION,
  destinationIntegrations,
} from '@/utils'
import Analytics from '@/services/analytics'
import { useRoute } from 'vue-router'

const footerElement: Ref<HTMLElement | null> = ref(null)
const footerId = 'page-footer'
const route = useRoute()
const settingStore = useSettingStore()
const componentStore = useComponentStore()
const footerSetting = computed(() => settingStore.pages?.general?.footer)
const shopSetting = computed<SettingPage>(() => settingStore.shop || {})
const trustpilotSettings = computed(
  () => settingStore.pages?.homePage?.trustpilot
)
const isShowTrustpilot = ref(false)

const copyRightText = computed(() => {
  return `© ${new Date().getFullYear()} ${shopSetting.value?.name}.`
})

onMounted(() => {
  runTaskOnIdleTime(() => {
    componentStore.isShowFooterLink = true
  })

  if (trustpilotSettings.value?.isShow && footerElement.value) {
    const options = {
      root: null,
      rootMargin: `${window.innerHeight}px 0px 0px 0px`,
      threshold: 0,
    }

    const observer = new IntersectionObserver(onFooterIntersect, options)
    observer.observe(footerElement.value)
  }
})

function onFooterIntersect(entries: IntersectionObserverEntry[]) {
  entries.forEach((entry) => {
    if (entry.target.id === footerId) {
      if (entry.isIntersecting) {
        isShowTrustpilot.value = true
      }
    }
  })
}

function trackingClickLogo() {
  const FOOTER_COMPONENT = 'footer'
  Analytics.track(
    EVENT_TRACKING_CLICK_LOGO,
    {
      path: route.path,
      component: FOOTER_COMPONENT,
    },
    destinationIntegrations([MIXPANEL_DESTINATION])
  )
}
</script>

<style lang="scss">
.footer {
  h3 {
    font-size: 16px;
  }

  ul {
    list-style: none;
    padding-left: 0;
  }

  address {
    font-style: unset;
  }

  &__link {
    margin-top: 3em;

    a {
      font-weight: normal;
    }

    li {
      margin-top: 1.5em;
    }
  }

  &__payments {
    margin-top: 3em;

    ul {
      display: flex;
      justify-content: space-evenly;

      .border-payment-icon {
        border: 1px solid var(--border-color);
        border-radius: var(--border-radius-small);
      }
    }
  }

  &__socials {
    margin-top: 3em;

    ul {
      display: flex;
      justify-content: space-evenly;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  padding-bottom: 3em;

  @include media-md-up {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      'a a a '
      'b b b '
      'c c d '
      'e e e';

    .divider {
      grid-area: a;
    }

    &__col:nth-child(2) {
      grid-area: b;
    }

    &__col:nth-child(3) {
      grid-area: c;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    &__col:nth-child(4) {
      grid-area: d;
    }

    &__link {
      margin-top: 0;
    }

    &__payments {
      margin-top: 0;
    }
  }

  @include media-lg-up {
    grid-template-columns: 3fr 2fr 2fr 2fr;
    column-gap: 1em;
    grid-template-areas:
      'a a a a'
      'b c c d'
      'e e e e';

    .divider {
      grid-area: a;
    }

    &__col:nth-child(2) {
      display: block;
    }

    h3 {
      margin-top: 0;
      font-size: 20px;
    }
  }
}
</style>
