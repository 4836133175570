import { NullOrType, Product } from '@/types'
import {
  CampaignDiscount,
  DiscountApplication,
  DiscountApplied,
  DiscountProgressMarker,
} from '@/types/discount'
import {
  DISCOUNT_CONDITION_ALL,
  DISCOUNT_CONDITION_PRODUCT_TAG,
  DISCOUNT_CONDITION_PRODUCT_TYPE,
  DISCOUNT_VALUE_TYPE_PRODUCT_FIXED_AMOUNT,
  DISCOUNT_VALUE_TYPE_PRODUCT_PERCENTAGE,
  DISCOUNT_CONDITION_BOGO,
  DISCOUNT_CONDITION_CART_VALUE,
  DISCOUNT_CONDITION_CART_ITEM_COUNT,
  CAMPAIGN_DISCOUNT_TYPE_CART_ITEM_COUNT,
  CAMPAIGN_DISCOUNT_TYPE_CART_VALUE,
  CAMPAIGN_DISCOUNT_TYPE_BUY_1_GET_1,
  DISCOUNT_CONDITION_VARIANT_SKU,
} from '@/utils'
import {
  buildDiscountMessage,
  buildDiscountValue,
  buildMakerLabel,
} from '@/utils/discount'
import {
  formatPrice,
  isProductHasTags,
  isProductHasType,
  isVariantSKUStartWiths,
} from '@/utils/product'
import { defineStore } from 'pinia'
import useCheckoutStore from '../checkout'

interface DiscountState {
  discountCode?: string
  campaignDiscountCode?: string
  campaignDiscount: NullOrType<CampaignDiscount>
  discountApplications: DiscountApplication[]
}

const useDiscountStore = defineStore('discount', {
  state: (): DiscountState => ({
    discountCode: '',
    campaignDiscountCode: '',
    campaignDiscount: null,
    discountApplications: [],
  }),
  getters: {
    cartDiscountApplication(state) {
      const checkoutStore = useCheckoutStore()
      // find the first applicable cart discount
      return state.discountApplications.find((discountApplication) => {
        let isApplicable = false
        for (let i = 0; i < discountApplication.conditions.length; i++) {
          const condition = discountApplication.conditions[i]
          switch (condition.applyFor) {
            case DISCOUNT_CONDITION_BOGO:
              if (checkoutStore.totalCartItems % 2 == 1) {
                isApplicable = true
              }
              break
            case DISCOUNT_CONDITION_CART_VALUE:
              isApplicable =
                checkoutStore.totalLineItemsPrice >= (condition.min || 0)
              break
            case DISCOUNT_CONDITION_CART_ITEM_COUNT:
              isApplicable =
                checkoutStore.totalCartItems + 1 >= (condition.min || 0)
              break
          }
          if (isApplicable) break
        }
        return isApplicable
      })
    },
    discountProgress(state) {
      if (!state.discountApplications.length || !state.campaignDiscount) {
        return null
      }

      const checkoutStore = useCheckoutStore()

      let discountCondition = ''
      let currentCartProgress = 0
      switch (state.campaignDiscount.type) {
        case CAMPAIGN_DISCOUNT_TYPE_CART_ITEM_COUNT:
          discountCondition = DISCOUNT_CONDITION_CART_ITEM_COUNT
          currentCartProgress = checkoutStore.totalCartItems
          break
        case CAMPAIGN_DISCOUNT_TYPE_CART_VALUE:
          discountCondition = DISCOUNT_CONDITION_CART_VALUE
          currentCartProgress = checkoutStore.totalLineItemsPrice
          break
        default:
          return null
      }

      let minValue = -1
      let maxValue = -1
      let markers: DiscountProgressMarker[] = []
      state.discountApplications.forEach((discountApplication) => {
        const conditionIndex = discountApplication.conditions.findIndex(
          (condition) =>
            condition.applyFor == discountCondition && !isNaN(condition.min!)
        )
        if (conditionIndex == -1) return

        const condition = discountApplication.conditions[conditionIndex]

        const marker: DiscountProgressMarker = {
          isPassed: false,
          position: 0,
          value: condition.min || 0,
          condition: discountCondition,
          conditionLabel: buildMakerLabel(
            discountCondition,
            condition.min || 0
          ),
          discountLabel: buildDiscountValue(
            discountApplication.valueType,
            discountApplication.value
          ),
        }
        if (minValue == -1 || minValue > marker.value) {
          minValue = marker.value
        }

        if (maxValue == -1 || maxValue < marker.value) {
          maxValue = marker.value
        }
        markers.push(marker)
      })

      const totalNumberOfMarker = markers.length
      if (totalNumberOfMarker == 0) return null
      markers.sort((a, b) => {
        return a.value - b.value
      })
      // add a marker to the first position to represent the starting point
      if (minValue > 0) {
        markers.unshift({
          isPassed: true,
          position: 0,
          value: 0,
          condition: discountCondition,
          conditionLabel: buildMakerLabel(discountCondition, 0),
          discountLabel: '',
        })
      }
      let nextCheckPoint: DiscountProgressMarker | null = null
      let currentCheckPoint = markers[0]
      markers = markers.map((marker) => {
        if (currentCartProgress >= marker.value) {
          marker.isPassed = true
          currentCheckPoint = marker
        } else {
          marker.isPassed = false
          if (!nextCheckPoint) {
            nextCheckPoint = marker
          }
        }
        marker.position = (marker.value / maxValue) * 100
        return marker
      })
      let message = 'Yay! You got the best value discount.'
      if (nextCheckPoint) {
        // fuck typescript
        const more = nextCheckPoint.value - currentCheckPoint.value
        if (discountCondition === DISCOUNT_CONDITION_CART_VALUE) {
          message = `Buy ${formatPrice(more)} more to get ${
            nextCheckPoint.discountLabel
          } off`
        } else if (discountCondition === DISCOUNT_CONDITION_CART_ITEM_COUNT) {
          message = `Buy ${more} more ${more > 1 ? 'items' : 'item'} to get ${
            nextCheckPoint.discountLabel
          } off`
        }
      }
      return {
        currentProgress: Math.round((currentCartProgress / maxValue) * 100),
        markers,
        message,
      }
    },
    isBOGOCampaign(state) {
      if (!state.campaignDiscount) return false
      return state.campaignDiscount.type === CAMPAIGN_DISCOUNT_TYPE_BUY_1_GET_1
    },
  },
  actions: {
    applyDiscount(
      product: Product,
      selectedVariantPrice: number,
      selectedVariantCompareAtPrice: number,
      variantSKU?: string
    ): DiscountApplied {
      selectedVariantPrice = +selectedVariantPrice
      selectedVariantCompareAtPrice = +selectedVariantCompareAtPrice

      const result: DiscountApplied = {
        amount: 0,
        amountFormated: '',
        messages: [],
        compareAtPrice:
          selectedVariantCompareAtPrice > selectedVariantPrice
            ? selectedVariantCompareAtPrice
            : selectedVariantPrice,
        price: selectedVariantPrice,
        applyDiscount(discountApplication: DiscountApplication) {
          // apply fake price
          if (discountApplication.fakePrice) {
            this.compareAtPrice =
              (this.compareAtPrice / 100) *
              (100 + discountApplication.fakePrice)

            this.price =
              (this.price / 100) * (100 + discountApplication.fakePrice)
          }

          // calculate discount value
          let discountAmount = 0
          switch (discountApplication.valueType) {
            case DISCOUNT_VALUE_TYPE_PRODUCT_PERCENTAGE:
              discountAmount = (this.price * discountApplication.value) / 100
              break
            case DISCOUNT_VALUE_TYPE_PRODUCT_FIXED_AMOUNT:
              discountAmount = this.price - discountApplication.value
          }

          // restricted discount value with max and min value
          if (discountApplication.minValue) {
            discountAmount = Math.min(
              discountApplication.minValue,
              discountAmount
            )
          }
          if (discountApplication.maxValue) {
            discountAmount = Math.max(
              discountApplication.maxValue,
              discountAmount
            )
          }

          const discountAmountPercentage = Math.round(
            (discountAmount / this.price) * 100
          )
          this.price = this.price - discountAmount
          if (discountAmount > 0) {
            this.amount += discountAmount
            this.messages.push(
              buildDiscountMessage(
                discountApplication.valueType,
                discountAmountPercentage,
                discountApplication.message
              )
            )
            this.amountFormated = buildDiscountValue(
              discountApplication.valueType,
              discountAmountPercentage
            )
          }
        },
      }

      // find the first applicable product based discount
      const selectedProductBasedDiscountApplication =
        this.discountApplications.find((discountApplication) => {
          let isApplicable = false
          // loop in all the conditions and find if the product is qualified at least one of them
          for (let i = 0; i < discountApplication.conditions.length; i++) {
            const condition = discountApplication.conditions[i]
            switch (condition.applyFor) {
              case DISCOUNT_CONDITION_ALL:
                isApplicable = true
                break
              case DISCOUNT_CONDITION_PRODUCT_TAG:
                isApplicable = isProductHasTags(product, condition.value || [])
                break
              case DISCOUNT_CONDITION_PRODUCT_TYPE:
                isApplicable = isProductHasType(product, condition.value || [])
                break
              case DISCOUNT_CONDITION_VARIANT_SKU:
                isApplicable = isVariantSKUStartWiths(
                  variantSKU,
                  condition.value
                )
            }
            if (isApplicable) break
          }
          return isApplicable
        })

      if (selectedProductBasedDiscountApplication) {
        result.applyDiscount(selectedProductBasedDiscountApplication)
      }

      if (this.cartDiscountApplication) {
        result.applyDiscount(this.cartDiscountApplication)
      }

      if (result.compareAtPrice <= result.price) result.compareAtPrice = 0

      return result
    },
  },
})

export default useDiscountStore
