<template>
  <div class="sign-in">
    <div class="sign-in-form">
      <form @submit.prevent="onSubmit" ref="form">
        <sib-form-item
          label="Email"
          input-id="sign-in-form__email"
          :value="customer.email"
          is-required
          :validator="isEmail"
          ref="itemEmail"
          error-message="Email is invalid"
        >
          <sib-input
            id="sign-in-form__email"
            size="sm"
            placeholder="Enter your email"
            v-model="customer.email"
            name="email"
          ></sib-input>
        </sib-form-item>

        <sib-form-item
          label="Password"
          input-id="sign-in-form__password"
          :value="customer.password"
          is-required
          ref="itemPassword"
        >
          <sib-input
            id="sign-in-form__password"
            size="sm"
            placeholder="Enter your password"
            v-model="customer.password"
            name="password"
            valueType="password"
            isShowPasswordIcon
          ></sib-input>
        </sib-form-item>

        <sib-link
          v-if="type == 'page'"
          class="sign-in-form__forgot-password"
          :to="'/account/recover'"
          :title="'Account Recover'"
        >
          Forgot your password?
        </sib-link>
        <div
          v-else
          class="sign-in-form__forgot-password"
          @click="$emit('recover-password')"
        >
          Forgot your password?
        </div>

        <sib-button
          class="sib-button--width-full mt-sm"
          role="primary"
          :loading="loading"
        >
          SIGN IN
        </sib-button>
        <p v-show="isError" class="sign-in-form__error-message">
          {{
            errorMessage == RESPONSE_API_ERROR_MESSAGE
              ? errorMessage
              : 'Incorrect email or password.'
          }}
        </p>
      </form>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  ref,
  reactive,
  nextTick,
  onUnmounted,
  computed,
  watch,
  onMounted,
} from 'vue'
import Analytics from '@/services/analytics'
import Logger from '@/services/log'
import FormItem from '@/ui-kits/FormItem.vue'
import {
  FORM_ITEM_INVALID_SELECTOR,
  HEADER_HEIGHT,
  RESPONSE_API_ERROR_MESSAGE,
  isEmail,
} from '@/utils'
import SibFormItem from '@/ui-kits/FormItem.vue'
import useCustomerStore from '@/store/customer'
import { useRouter } from 'vue-router'
import { usePage } from '@/composables/page'

const emit = defineEmits<{
  (e: 'sign-in', value: boolean): void
  (e: 'recover-password'): void
}>()

const props = withDefaults(defineProps<{ type?: string }>(), {
  type: 'page',
})

const { setPageTitle } = usePage()
const customerStore = useCustomerStore()
const router = useRouter()

const errorMessage = computed(() => customerStore.errorMessage)
const customerEmail = computed(() => customerStore.customerEmail)

const customer = reactive({
  email: customerEmail.value || '',
  password: '',
})
const itemEmail = ref<typeof FormItem | null>(null)
const itemPassword = ref<typeof FormItem | null>(null)
const form = ref<HTMLFormElement | null>(null)
const loading = ref(false)
const isError = ref(false)

onMounted(() => {
  watch(
    () => customerEmail.value,
    (value) => {
      if (value) {
        customer.email = value
      }
    },
    { immediate: true }
  )
})
onUnmounted(() => {
  customerStore.errorMessage = null
})

async function onSubmit() {
  const isEmailValid = itemEmail.value?.validate()
  const isPasswordValid = itemPassword.value?.validate()
  const isValid = isEmailValid && isPasswordValid

  if (!isValid) {
    // wait a tick for Vue applying the classes
    await nextTick()
    const firstInvalidElement: HTMLElement | undefined | null =
      form.value?.querySelector(FORM_ITEM_INVALID_SELECTOR)
    if (firstInvalidElement) {
      window.scrollTo({
        top:
          window.scrollY +
          firstInvalidElement.getBoundingClientRect().top -
          HEADER_HEIGHT,
        behavior: 'smooth',
      })
    }
    return
  }

  if (!form.value) return

  loading.value = true
  isError.value = false

  try {
    const accessToken = await customerStore.createCustomerAccessToken(customer)
    if (accessToken) {
      const customer = await customerStore.getCurrentCustomer()

      if (customer) {
        emit('sign-in', true)

        if (props.type == 'page') {
          router.push('/account/customer')
          setPageTitle('Customer')
          return
        }
      }
    } else {
      isError.value = true
      emit('sign-in', false)
    }
    window.scrollTo({ top: 0, behavior: 'smooth' })
  } catch (error: any) {
    Logger.error('Error when sign in', error)
    Analytics.error(error)
  }
  loading.value = false
}
</script>

<style lang="scss">
.sign-in {
  &-form {
    &__paragraph {
      strong {
        font-size: 1.5rem;
      }
    }
    &__error-message {
      color: var(--color-secondary);
    }
    &__forgot-password {
      color: var(--color-primary);
      margin-top: 8px;
      cursor: pointer;
      font-weight: 600;
      &:hover {
        text-decoration: underline;
      }
    }

    .sib-form-item {
      margin: 1em 0;
      @include media-md-up {
        margin-top: 1.75em;
      }
    }
  }
}
</style>
