// All tracking events name
export const EVENT_TRACKING_PRODUCT_VIEWED = 'Product Viewed'
export const EVENT_TRACKING_PRODUCT_ADDED = 'Product Added'
export const EVENT_TRACKING_PRODUCT_REMOVED = 'Product Removed'
export const EVENT_TRACKING_CART_VIEWED = 'Cart Viewed'
export const EVENT_TRACKING_CHECKOUT_VIEWED = 'Checkout Viewed'
export const EVENT_TRACKING_CHECKOUT_STEP_VIEWED = 'Checkout Step Viewed'
export const EVENT_TRACKING_CHECKOUT_STEP_COMPLETED = 'Checkout Step Completed'
export const EVENT_TRACKING_PRODUCTS_SEARCHED = 'Products Searched'
export const EVENT_TRACKING_BUTTON_ADD_TO_CART_CLICKED =
  'Button Add To Cart Clicked'
export const EVENT_TRACKING_BUTTON_BUY_NOW_CLICKED = 'Button Buy Now Clicked'
export const EVENT_TRACKING_GO_TO_CHECKOUT = 'Go To Checkout'
export const EVENT_TRACKING_VISIBILITY_CHANGE = 'Visibility Change'
export const EVENT_TRACKING_CLICK_ADD_TO_CART_ON_PREVIEW =
  'Click Add To Cart On Preview Modal'

export const EVENT_TRACKING_APP_MOUNTED = 'App mounted'
export const EVENT_TRACKING_ANALYTICS_START_LOADING = 'Analytics start loading'
export const EVENT_TRACKING_ANALYTICS_LOADED = 'Analytics loaded'
export const EVENT_TRACKING_PERFORMANCE_CLS = 'Performance CLS'
export const EVENT_TRACKING_PERFORMANCE_FID = 'Performance FID'
export const EVENT_TRACKING_PERFORMANCE_LCP = 'Performance LCP'
export const EVENT_TRACKING_PERFORMANCE_FCP = 'Performance FCP'
export const EVENT_TRACKING_PERFORMANCE_TTFB = 'Performance TTFB'
export const EVENT_TRACKING_CART_LOADED = 'Cart loaded'
export const EVENT_TRACKING_PAGE_SEARCH_VIEWED = 'Page Search Viewed'
export const EVENT_TRACKING_SEARCH_RESULT_CLICKED = 'Search Result Clicked'
export const EVENT_TRACKING_CUSTOMILY_START_LOADING = 'Customily start loading'
export const EVENT_TRACKING_CUSTOMILY_LOADED = 'Customily loaded'
export const EVENT_TRACKING_CUSTOMER_PERSONALIZED = 'Customer Personalized'
export const EVENT_TRACKING_CUSTOMER_PREVIEW_PERSONALIZATION =
  'Customer Preview Personalization'
export const EVENT_TRACKING_UTM_CHANGE = 'UTM change'
export const EVENT_TRACKING_PAGE_HIDE = 'Page Hide'
export const EVENT_TRACKING_USER_SCROLL = 'User scroll'
export const EVENT_TRACKING_SHOW_PRODUCT_DESCRIPTION =
  'Show Product Description'
export const EVENT_TRACKING_SHOW_PRODUCT_SHIPPING = 'Show Product Shipping'
export const EVENT_TRACKING_SHOW_PRODUCT_RECOMMENDATION =
  'Show Product Recommendation'
export const EVENT_TRACKING_SHOW_PRODUCT_REVIEWS = 'Show Product Reviews'
export const EVENT_TRACKING_SHOW_PRODUCT_RECENTLY_VIEWED =
  'Show Product Recently Viewed'
export const EVENT_TRACKING_PAGE_COLLECTION_VIEWED = 'Product List Viewed'
export const EVENT_TRACKING_OPEN_COLLECTION_FILTER = 'Open Collection Filter'
export const EVENT_TRACKING_CLICK_COLLECTION_FILTER = 'Click Collection Filter'
export const EVENT_TRACKING_SORT_COLLECTION = 'Sort Collection'
export const EVENT_TRACKING_PRODUCT_RECOMMENDATION_CLICKED =
  'Product Recommendation Clicked'
export const EVENT_TRACKING_PRODUCT_RECENTYLY_VIEWED_CLICKED =
  'Product Recently Viewed Clicked'
export const EVENT_TRACKING_PRODUCT_DESCRIPTION_DETAILS_OPENED =
  'Product Description Details Opened'
export const EVENT_TRACKING_PRODUCT_SHIPPING_DETAILS_OPENED =
  'Product Shipping Details Opened'
export const EVENT_TRACKING_PRODUCT_SIZE_GUIDE_OPENED =
  'Product Size Guide Opened'
export const EVENT_TRACKING_PRODUCT_SHIPPED_FROM_OPENED =
  'Product Shipped From Opened'
export const EVENT_TRACKING_SHOW_PRODUCT_SIZE_GUIDE = 'Show Product Size Guide'
export const EVENT_TRACKING_SORT_REVIEW = 'Sort Review'
export const EVENT_TRACKING_CLICK_REVIEW_PAGINATION = 'Click Review Pagination'
export const EVENT_TRACKING_CLICK_VIEW_CART_PAGE = 'Click Button View Cart Page'
export const EVENT_TRACKING_CLICK_BUTTON_NICHE_COLLECTION =
  'Click Button Niche Collection'
export const EVENT_TRACKING_CLICK_TYPE_COLLECTION =
  'Click Button Type Collection'
export const EVENT_TRACKING_CLICK_BREADCRUMB = 'Click Breadcrumb'
export const EVENT_TRACKING_CLICK_MENU = 'Click Menu'
export const EVENT_TRACKING_CLICK_LOGO = 'Click Logo'
export const EVENT_TRACKING_CLICK_PREVIEW_ON_CART = 'Click Preview On Cart'
export const EVENT_TRACKING_CLICK_PERSONALIZED_OPTIONS_ON_CART =
  'View Personalized Options On Cart'
export const EVENT_TRACKING_LOCATION_BLOCKED = 'Location Blocked'
export const EVENT_TRACKING_PRODUCT_BLOCKED = 'Product Blocked'
export const EVENT_TRACKING_CLICK_EDIT_PERSONALIZATION =
  'Click Edit Personalization'
export const EVENT_TRACKING_CHECKOUT_IS_NOT_VALID = 'Checkout Is Not Valid'

export const EVENT_TRACKING_NAVIGATION_ERROR = 'Navigation error'
export const EVENT_TRACKING_INTELLECTUAL_PROPERTY_CLAIM =
  'Intellectual Property Claim'
export const EVENT_TRACKING_LIVE_CHAT_ADDED = 'Live Chat Added'
export const EVENT_TRACKING_LIVE_CHAT_LOADED = 'Live Chat Loaded'
export const EVENT_TRACKING_LIVE_CHAT_ERROR = 'Live Chat Error'
export const PREVIEW_IMAGE_ON_CART_LOAD_FAILED =
  'Preview Image On Cart Load Failed'
export const PREVIEW_IMAGE_ON_CART_RE_FETCHED =
  'Preview Image On Cart Re Fetched'
export const CUSTOMILY_PRODUCT_ADDED_ERROR = 'Customily Product Added Error'

export const EVENT_USER_FIRST_INPUT = 'User First Input'
export const EVENT_USER_SUBSCRIBE = 'User Subscribe'

export const EVENT_ABTEST_ASSIGNED = 'AB Test Assigned'

export const FB_EVENT_ADD_TO_CART = 'AddToCart'
export const FB_EVENT_CUSTOMIZE_PRODUCT = 'CustomizeProduct'
export const FB_EVENT_INITIATE_CHECKOUT = 'InitiateCheckout'
export const FB_EVENT_PURCHASE = 'Purchase'
export const FB_EVENT_SEARCH = 'Search'
export const FB_EVENT_VIEW_CONTENT = 'ViewContent'
export const FB_EVENT_PAGE_VIEW = 'PageView'

export const GTM_EVENT_PAGE_VIEW = 'PageView'

export const EVENT_TRACKING_OPEN_SORT_OPTION = "Open Sort Option"
export const EVENT_TRACKING_OPEN_MENU_MOBILE = "Open Menu Mobile"
export const EVENT_TRACKING_CLICK_SECOND_MENU = "Click Second Menu"

export const EVENT_NEW_HOMEPAGE_VIEWED = "New Home Page Viewed"
export const EVENT_NEW_HOME_PAGE_SCROLL = 'New Home Page Scroll'
export const EVENT_LANDING_PAGE_VIEWED = 'Landing Page Viewed'