<template>
  <ul class="breadcrumb">
    <li
      class="breadcrumb__item"
      v-for="(item, index) of breadcrumbItems"
      :key="index"
    >
      <template v-if="index < breadcrumbItems.length - 1">
        <sib-link
          :to="item.url"
          :title="item.name"
          @click="trackingClickBreadcrumb(item.url)"
        >
          {{ item.name }}
        </sib-link>
        <ArrowRight class="breadcrumb__arrow" />
      </template>
      <template v-else>
        {{ item.name }}
      </template>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { computed, ComputedRef } from '@vue/reactivity'
import ArrowRight from '@/assets/images/icons/arrow-right.svg'
import { onServerPrefetch, useSSRContext } from 'vue'
import { appendToHead } from '@/composables/appendToHead'
import { isOnServer } from '@/utils/ssr'
import {
  EVENT_TRACKING_CLICK_BREADCRUMB,
  SSR_CONTEXT_APPEND_TO_HEAD_JSON_LD,
} from '@/utils/constants'
import { MIXPANEL_DESTINATION, destinationIntegrations } from '@/utils'
import Analytics from '@/services/analytics'
import { useRoute } from 'vue-router'

const route = useRoute()

interface BreadCrumbItem {
  url: string
  name: string
}

interface BreadCrumbProps {
  items: BreadCrumbItem[]
}

const props = withDefaults(defineProps<BreadCrumbProps>(), {
  items: () => [],
})

const breadcrumbItems: ComputedRef<BreadCrumbItem[]> = computed(() => {
  return [
    {
      url: '/',
      name: 'Home',
    },
    ...props.items,
  ]
})

const ssrContext = isOnServer && useSSRContext()

onServerPrefetch(() => {
  if (ssrContext) {
    const breadcrumbJsonLdScript = renderBreadcrumbJsonLd()
    appendToHead(
      breadcrumbJsonLdScript,
      SSR_CONTEXT_APPEND_TO_HEAD_JSON_LD,
      ssrContext
    )
  }
})

function trackingClickBreadcrumb(targetPath: string) {
  Analytics.track(
    EVENT_TRACKING_CLICK_BREADCRUMB,
    {
      path: route.path,
      target_path: targetPath,
    },
    destinationIntegrations([MIXPANEL_DESTINATION])
  )
}

const renderBreadcrumbJsonLd = function () {
  const breadcrumbJsonLd = JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbItems.value.map((item, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@id': item.url,
        name: item.name,
      },
    })),
  })

  const breadcrumbJsonLdScript = `<script type="application/ld+json">${breadcrumbJsonLd}<\/script>`

  return breadcrumbJsonLdScript
}
</script>

<style lang="scss">
.breadcrumb {
  display: flex;
  list-style: none;
  padding: 0;
  gap: 1em;
  font-size: 12px;

  &__item {
    a {
      font-weight: 400;
    }

    &:first-child {
      a {
        color: var(--color-primary);
      }
    }

    &:nth-child(n + 3) {
      display: none;
    }

    &:nth-child(n + 2) {
      .breadcrumb__arrow {
        display: none;
      }
    }
  }

  &__arrow {
    margin-left: 1em;
  }
}
</style>
