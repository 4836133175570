<template>
  <span :class="classes">
    <slot></slot>
  </span>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { UIKitRole, UIKitSize, UIKitRound } from './types'

const baseClass = 'sib-tag'

interface InputProps {
  role?: UIKitRole
  size?: UIKitSize
  round?: UIKitRound
}

const props = withDefaults(defineProps<InputProps>(), {
  role: 'default',
  size: 'md',
  round: 'md',
})

const classes = computed(() => {
  const classes = [baseClass]
  if (props.role) {
    classes.push(`${baseClass}--role-${props.role}`)
  }
  if (props.size) {
    classes.push(`${baseClass}--size-${props.size}`)
  }
  if (props.round) {
    classes.push(`${baseClass}--round-${props.round}`)
  }
  return classes
})
</script>
