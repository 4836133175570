import CacheService from '@/services/cache'
import { useCookier } from '@/services/cookie'
import Logger from '@/services/log'
import useABTestStore from '@/store/abtest'
import useRouteStore from '@/store/route'
import useSettingStore from '@/store/setting'
import { ABTest, ABTestVariant } from '@/types'
import { CACHE_KEY_AB_TEST, ONE_YEAR_IN_SECONDS } from '@/utils'
import { buildCookieKey } from '@/utils/cache'
import { useRoute, LocationQuery } from 'vue-router'

export const useABTest = function () {
  const settingStore = useSettingStore()
  const abTestStore = useABTestStore()
  const routeStore = useRouteStore()
  const route = useRoute()
  const cookier = useCookier()

  function runABTests() {
    const abTests = settingStore.abTests
    if (!abTests || !abTests.length) return
    abTests.forEach((abTest) => {
      // check if the A/B test is active or disable
      if (!isABTestActive(abTest)) return
      // check if the A/B test is assigned or not
      if (isABTestAssigned(abTest)) return
      // If the A/B test has not yet been assigned, then check if it is eligible to be activated.
      if (!isABTestTrigger(abTest, route.query)) return
      // assign the A/B test if it actived
      assignABTest(abTest)
    })
  }

  function buildABTestCookieName(abTestCode: string) {
    return buildCookieKey('abtest_' + abTestCode)
  }

  function setAssignedABTest(abTest: ABTest, assignedVariant: ABTestVariant) {
    const clonedABbTest = { ...abTest }
    clonedABbTest.assignedVariant = {
      code: assignedVariant.code,
      name: assignedVariant.name,
      weight: assignedVariant.weight,
    }
    if (!abTestStore.assignedABTests) {
      abTestStore.assignedABTests = []
    }
    abTestStore.assignedABTests.push(clonedABbTest)
  }

  function isABTestAssigned(abTest: ABTest): boolean {
    if (!abTest.variants?.length) return false
    const abTestCookieKey = buildABTestCookieName(abTest.code)
    const cookieValue = cookier.getCookie(abTestCookieKey)
    if (!cookieValue) return false
    for (let i = 0; i < abTest.variants.length; i++) {
      const variant = abTest.variants[i]
      if (cookieValue === variant.code) {
        setAssignedABTest(abTest, variant)
        return true
      }
    }
    // there is no matching variant with the current cookie
    // delete the cookie
    cookier.deleteCookie(abTestCookieKey, {
      domain: '.' + routeStore.currentDomain,
    })
    return false
  }

  async function assignABTest(abTest: ABTest) {
    if (!abTest.variants?.length) return
    let totalWeight = 0
    const variants: Array<
      ABTestVariant & {
        min: number
        max: number
      }
    > = []
    abTest.variants.forEach((variant) => {
      const min = totalWeight
      totalWeight += variant.weight
      variants.push({
        ...variant,
        min,
        max: totalWeight,
      })
    })
    const assignedValue = Math.floor(Math.random() * totalWeight)
    const assignedVariant = variants.find(
      (variant) => variant.min <= assignedValue && assignedValue < variant.max
    )
    if (assignedVariant) {
      const cookieKey = buildABTestCookieName(abTest.code)
      cookier.setCookie(cookieKey, assignedVariant.code, {
        maxage: ONE_YEAR_IN_SECONDS,
        domain: '.' + routeStore.currentDomain,
      })
      Logger.log(
        'assign A/B test',
        {
          code: abTest.code,
          variant: assignedVariant.code,
          cacheInited: !!CacheService.instance,
        },
        1
      )
      setAssignedABTest(abTest, assignedVariant)
      if (!CacheService.instance) return
      const cacheKey = `${CACHE_KEY_AB_TEST}:${abTest.code}:${assignedVariant.code}`
      const currentValueFromCache = await CacheService.instance.get(cacheKey)
      const currentValue = +currentValueFromCache?.value || 0
      // increase abtest count in cache service
      CacheService.instance?.set(cacheKey, currentValue + 1)
    }
  }

  function isABTestActive(abTest: ABTest) {
    if (!abTest) return false
    if (!abTest.active) return false
    return true
  }

  function isABTestTrigger(abTest: ABTest, queryStrings: LocationQuery) {
    if (!abTest) return false
    if (!abTest.trigger) return true
    // only restrict the A/B test with querystring in case the option is explicitly set to true
    // and there are querystring in the list
    if (
      !abTest.trigger.isRestrictedWithQueryString ||
      !abTest.trigger.triggeringQueryStrings ||
      !abTest.trigger.triggeringQueryStrings.length
    )
      return true
    // the querystring need to include all the configured query string to activate the A/B test
    for (let i = 0; i < abTest.trigger.triggeringQueryStrings.length; i++) {
      const queryString = abTest.trigger.triggeringQueryStrings[i]
      if (queryStrings[queryString.key] != queryString.value) return false
    }
    return true
  }

  function getABTestActivedVariantByCode(abTestCode: string) {
    if (
      !abTestCode ||
      !abTestStore.assignedABTests ||
      !abTestStore.assignedABTests.length
    )
      return null
    const abTest = abTestStore.assignedABTests.find(
      (elm) => elm.code === abTestCode
    )
    if (!abTest || !abTest.assignedVariant) return null
    return abTest.assignedVariant.code
  }

  return {
    runABTests,
    isABTestAssigned,
    assignABTest,
    isABTestActive,
    getABTestActivedVariantByCode,
  }
}
