import { isOnServer } from '@/utils/ssr'
import type * as RudderStackAnalytics from './rudderstack-wrapper'
import Logger from '../log'
import CacheService, { getJSONfromCacheResponse } from '../cache'
import {
  CACHE_KEY_UTMS,
  ONE_DAY_IN_MILLISECONDS,
  THIRTY_DAYS_IN_MILLISECONDS,
  TIME_MARK_CSR_ANALYTICS_START,
  EVENT_TRACKING_ANALYTICS_START_LOADING,
  EVENT_TRACKING_ANALYTICS_LOADED,
  EVENT_TRACKING_UTM_CHANGE,
  MEASURE_NAME_ANALYTICS_LOADED,
  MIXPANEL_DESTINATION,
  destinationIntegrations,
  FIRST_PAGE_KEY,
  COOKIE_KEY_ATTRIBUTE_UTM_PREFIX,
  ONE_DAY_IN_SECONDS,
} from '@/utils'
import { CookieManager } from '@/services/cookie'

export interface AnalyticsService {
  instance: null | typeof RudderStackAnalytics
  providers: AnalyticsProvider[]
  readyCallbacks: Array<() => void>
  init: () => void
  excuteOnReady: (callback: () => void) => void
  parseUTMParams: () => void
  setCookieDomainKlaviyo: () => void
  track: typeof RudderStackAnalytics.track
  identify: typeof RudderStackAnalytics.identify
  page: typeof RudderStackAnalytics.page
  alias: typeof RudderStackAnalytics.alias
  reset: typeof RudderStackAnalytics.reset
  error: (error: Error, additionData?: any) => void
  getCurrentUTMs: () => Promise<Array<UTM> | null>
  setUtms: (utms: Array<UTM> | null) => void
  getCookieCurrentUTMs: () => UTM | null
  setCookieUtms: () => void
  getCookieFirstPage: () => string | null
  setCookieFirstPage: () => void
  getUserTraits: () => any
  getAnonymousId: () => any
}

export interface UTM {
  campaign?: string
  source?: string
  medium?: string
  term?: string
  content?: string
  id: number
  referrer: string
}

const Analytics: AnalyticsService = {
  instance: null,
  providers: [],
  readyCallbacks: [],

  async init() {
    if (isOnServer) return
    performance.mark(TIME_MARK_CSR_ANALYTICS_START)
    this.track(
      EVENT_TRACKING_ANALYTICS_START_LOADING,
      {
        time: Date.now(),
        performance_time: performance.now(),
      },
      destinationIntegrations([MIXPANEL_DESTINATION])
    )

    // initiate providers
    // use dynamic import to split providers into chunks
    // ;(async () => {
    //   try {
    //     const SentryAnalytics = (await import('./sentry')).default
    //     this.providers.push(SentryAnalytics)
    //     SentryAnalytics.init()
    //   } catch (error) {
    //     Logger.error('Error when init sentry', error)
    //   }
    // })()
    ;(async () => {
      try {
        const ShopifyAnalytics = (await import('./shopify')).default
        this.providers.push(ShopifyAnalytics)
        ShopifyAnalytics.init()
      } catch (error) {
        Logger.error('Error when init shopify analytics', error)
      }
    })()

    // initiate rudder stack sdk
    this.instance = await import('./rudderstack-wrapper')
    this.instance.load(
      import.meta.env.VITE_TRACKING_WRITE_KEY as string,
      import.meta.env.VITE_TRACKING_DATA_PLANE_URL as string,
      {
        // using xhr mode for now for debug purpose
        // useBeacon: true,
      }
    )

    // Set cookie domain for klaviyo to track between subdomains.
    this.setCookieDomainKlaviyo()

    // parse utm params and save to user traits
    // make sure this function is fired first before any other function
    await this.parseUTMParams()
    this.setCookieUtms()

    this.instance.ready(() => {
      Logger.log('Analytics', 'ready')
      const selfTimeAnalyticsLoad = performance.measure(
        MEASURE_NAME_ANALYTICS_LOADED,
        TIME_MARK_CSR_ANALYTICS_START
      ).duration
      this.track(
        EVENT_TRACKING_ANALYTICS_LOADED,
        {
          time: Date.now(),
          performance_time: performance.now(),
          self_time: selfTimeAnalyticsLoad,
        },
        destinationIntegrations([MIXPANEL_DESTINATION])
      )
    })

    // excute ready callbacks
    this.readyCallbacks.forEach((callback) => {
      this.instance?.ready(callback)
    })
    this.readyCallbacks = []
  },

  async parseUTMParams() {
    if (isOnServer) return
    const utmParamPrefix = 'utm_'
    const firstPageKey = '_firstPage'
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search.slice(1))
    const utm: UTM = {
      id: Date.now(),
      referrer: document.referrer,
    }
    let hasUTMParams = false
    const utmParams: { [key: string]: string } = {}
    const allowUTMParamsToDetectChange = [
      'campaign',
      'source',
      'medium',
      'term',
      'content',
    ]

    // get all params that starts with utm prefix
    params.forEach((value, key) => {
      if (key && key.startsWith(utmParamPrefix)) {
        utmParams[key] = value
        const keyWithoutPrefix = key.substring(
          utmParamPrefix.length
        ) as keyof UTM
        // ignore key == id to avoid TS error
        if (keyWithoutPrefix != 'id') {
          utm[keyWithoutPrefix] = value
        }
        if (allowUTMParamsToDetectChange.includes(keyWithoutPrefix)) {
          hasUTMParams = true
        }
      }
    })
    if (hasUTMParams) {
      const currentUtms = (await this.getCurrentUTMs()) || []
      const lastUtm = currentUtms[currentUtms.length - 1]
      if (!isTwoUTMIdentical(lastUtm, utm)) {
        this.track(EVENT_TRACKING_UTM_CHANGE, utmParams)
        Logger.log('Pushing new UTM', utm, 2)
        currentUtms.push(utm)
        this.setUtms(currentUtms)
      }
    }

    this.setCookieFirstPage()
    const currentUserTraits = this.instance?.getUserTraits()
    if (!currentUserTraits) return
    const firstPage = currentUserTraits[firstPageKey]
    if (firstPage) {
      return
    }
    const payload: any = {}

    if (!firstPage) {
      payload[firstPageKey] = window.location.href
    }

    const anonymousId = this.instance?.getAnonymousId()
    if (anonymousId) this.identify(anonymousId, payload)
    else this.identify(payload)
  },

  setCookieDomainKlaviyo() {
    if (isOnServer) return
    const href = new URL(window.location.href)
    const domain = href.hostname
    const domainParts = domain.split('.')
    const currentDomain = domainParts.slice(-2).join('.')
    window._learnq = window._learnq || []
    _learnq.push(['cookieDomain', `.${currentDomain}`])
  },

  excuteOnReady(callback: () => void) {
    if (isOnServer) return
    if (!this.instance) {
      this.readyCallbacks.push(callback)
      return
    }
    this.instance.ready(callback)
  },

  // this will show an TS error. Don't mind it. We have no choice
  track(...args: Parameters<typeof RudderStackAnalytics.track>) {
    if (isOnServer) return
    if (!this.instance) {
      return this.excuteOnReady(() => {
        this.track(...args)
      })
    }
    Logger.log('Analytic track', ...args, 2)
    this.providers.forEach((provider) => {
      provider.track(...args)
    })
    return this.instance.track(...args)
  },
  identify(...args: Parameters<typeof RudderStackAnalytics.identify>) {
    if (isOnServer) return
    if (!this.instance) {
      return this.excuteOnReady(() => {
        return this.identify(...args)
      })
    }
    Logger.log('Analytic identify', ...args, 2)
    return this.instance.identify(...args)
  },
  page(...args: Parameters<typeof RudderStackAnalytics.page>) {
    if (isOnServer) return
    if (!this.instance) {
      return this.excuteOnReady(() => {
        return this.page(...args)
      })
    }
    Logger.log('Analytic page', ...args, 2)
    this.providers.forEach((provider) => {
      provider.page(...args)
    })
    return this.instance.page(...args)
  },
  alias(...args: Parameters<typeof RudderStackAnalytics.alias>) {
    if (isOnServer) return
    if (!this.instance) {
      return this.excuteOnReady(() => {
        this.alias(...args)
      })
    }
    Logger.log('Analytic alias', ...args, 2)
    this.providers.forEach((provider) => {
      provider.alias(...args)
    })
    return this.instance.alias(...args)
  },
  reset(...args: Parameters<typeof RudderStackAnalytics.reset>) {
    if (isOnServer) return
    if (!this.instance) {
      return this.excuteOnReady(() => {
        this.reset(...args)
      })
    }
    Logger.log('Analytic reset', ...args, 2)
    this.providers.forEach((provider) => {
      provider.reset(...args)
    })

    return this.instance.reset(...args)
  },
  error(error: Error, additionData?: any) {
    if (isOnServer) return
    Logger.log('Analytic tracking error', error, additionData)

    this.providers.forEach((provider) => {
      provider.error ? provider.error(error, additionData) : ''
    })
  },
  async getCurrentUTMs() {
    if (isOnServer) return
    const utmsFromCache = await CacheService.instance?.get(CACHE_KEY_UTMS)
    if (utmsFromCache) {
      return getJSONfromCacheResponse(utmsFromCache)
    }
    return null
  },
  setUtms(utms: Array<UTM> | null) {
    if (isOnServer) return
    return CacheService.instance?.set(
      CACHE_KEY_UTMS,
      utms,
      THIRTY_DAYS_IN_MILLISECONDS
    )
  },
  getCookieCurrentUTMs() {
    if (isOnServer) return null
    const utmParamPrefix = 'utm_'
    const utmKeys = ['campaign', 'source', 'medium', 'term', 'content', 'id']
    const cookier = new CookieManager()
    const referer = cookier.getCookie(
      `${COOKIE_KEY_ATTRIBUTE_UTM_PREFIX}referer`
    )
    const utmsFromCookie: any = referer ? { referer } : {}
    utmKeys.forEach((key) => {
      const cookieKey = `${COOKIE_KEY_ATTRIBUTE_UTM_PREFIX}${utmParamPrefix}${key}`
      const value = cookier.getCookie(cookieKey)
      if (value) {
        utmsFromCookie[key] = value
      }
    })
    if (Object.keys(utmsFromCookie).length) {
      return utmsFromCookie
    }
    return null
  },

  // clone metorik soure code
  async setCookieUtms() {
    if (isOnServer) return
    const getUTMParams = function (custom_params?: any) {
      const query_string: any = {},
        query = custom_params
          ? custom_params
          : window.location.search.substring(1),
        vars = query.split('&')

      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=')
        if (typeof query_string[pair[0]] === 'undefined') {
          query_string[pair[0]] = pair[1]
        } else if (typeof query_string[pair[0]] === 'string') {
          const arr = [query_string[pair[0]], pair[1]]
          query_string[pair[0]] = arr
        } else {
          query_string[pair[0]].push(pair[1])
        }
      }

      return query_string
    }

    const cookier = function (name: any, value?: any, ms?: any) {
      if (arguments.length < 2) {
        // read cookie
        const cookies = document.cookie.split(';')
        for (let i = 0; i < cookies.length; i++) {
          const c = cookies[i].replace(/^\s+/, '')
          if (c.indexOf(name + '=') == 0) {
            return decodeURIComponent(
              c
                .substring(name.length + 1)
                .split('+')
                .join(' ')
            )
          }
        }
        return null
      }

      // write cookie
      const date = new Date()
      date.setTime(date.getTime() + ms)
      document.cookie =
        name +
        '=' +
        encodeURIComponent(value) +
        (ms ? ';expires=' + date.toGMTString() : '') +
        ';path=/'
    }

    // Keys to process.
    const utmParamPrefix = 'utm_'
    const utmKeys = ['campaign', 'source', 'medium', 'term', 'content', 'id']

    // Go through each key.
    utmKeys.forEach(function (key) {
      const cookieKey = `${COOKIE_KEY_ATTRIBUTE_UTM_PREFIX}${utmParamPrefix}${key}`

      // Continue if cookie not set and key exists.
      if (!cookier(cookieKey) && `${utmParamPrefix}${key}` in getUTMParams()) {
        // Set a cookie for 12 hours so we don't store again.
        cookier(
          cookieKey,
          getUTMParams()[`${utmParamPrefix}${key}`],
          1000 * 60 * 60 * 12 // 12 hours
        )
      }
    })

    // Track the referring site if not from this site.
    if (
      !cookier(`${COOKIE_KEY_ATTRIBUTE_UTM_PREFIX}referer`) &&
      document.referrer &&
      !(
        document.referrer.indexOf(location.protocol + '//' + location.host) ===
        0
      )
    ) {
      // Set a cookie for 1 day so we don't store again.
      cookier(
        `${COOKIE_KEY_ATTRIBUTE_UTM_PREFIX}referer`,
        document.referrer,
        1000 * 60 * 60 * 24 // 1 day
      )
    }
  },

  getCookieFirstPage() {
    const cookier = new CookieManager()
    const firstPageFromCookie = cookier.getCookie(`${FIRST_PAGE_KEY}`)
    if (firstPageFromCookie) return firstPageFromCookie
    return null
  },

  setCookieFirstPage() {
    const cookier = new CookieManager()
    const firstPageFromCookie = cookier.getCookie(`${FIRST_PAGE_KEY}`)
    if (!firstPageFromCookie) {
      cookier.setCookie(`${FIRST_PAGE_KEY}`, window.location.href, {
        maxage: ONE_DAY_IN_SECONDS,
      })
    }
  },
  getUserTraits() {
    if (isOnServer) return
    return this.instance?.getUserTraits()
  },
  getAnonymousId() {
    if (isOnServer) return
    return this.instance?.getAnonymousId()
  },
}

export default Analytics

export interface AnalyticsProvider {
  isActive: boolean
  init: () => void
  track: (...args: any) => void
  page: (...args: any) => void
  alias: (...args: any) => void
  reset: (...args: any) => void
  error?: (...args: any) => void
}

function isTwoUTMIdentical(utm1: UTM, utm2: UTM): boolean {
  if (!utm1 || !utm2) return false
  const {
    campaign: campaign1,
    source: source1,
    medium: medium1,
    term: term1,
    content: content1,
    id: id1,
  } = utm1

  const {
    campaign: campaign2,
    source: source2,
    medium: medium2,
    term: term2,
    content: content2,
    id: id2,
  } = utm2
  if (
    campaign1 === campaign2 &&
    source1 === source2 &&
    medium1 === medium2 &&
    term1 === term2 &&
    content1 === content2
  ) {
    if (!id1 || !id2) return false
    // if two UTM has the gap greater than one day. They are consider as different
    if (Math.abs(id1 - id2) >= ONE_DAY_IN_MILLISECONDS) {
      return false
    }
    return true
  }
  return false
}
