import CacheService from '@/services/cache'
import useComponentStore from '@/store/component'
import useDiscountStore from '@/store/discount'
import useSettingStore from '@/store/setting'
import { CampaignDiscount, DiscountApplication } from '@/types/discount'
import {
  DISCOUNT_CODE_DIVIDER,
  DISCOUNT_CONDITION_ALL,
  DISCOUNT_VALUE_TYPE_PRODUCT_PERCENTAGE,
  CACHE_KEY_CAMPAIGN_DISCOUNT_CODE,
  CACHE_KEY_DISCOUNT_CODE,
  PROMOTION_BAR_PRIORITY_MEDIUM,
  PROMOTION_BAR_TYPE_DISCOUNT,
  PROMOTION_DISCOUNT_MESSAGE_DEFAULT,
  QUERY_STRING_CAMPAIGN_DISCOUNT,
  QUERY_STRING_DISCOUNT,
  SEVEN_DAYS_IN_MILLISECONDS,
  DISCOUNT_FREE_SHIPPING_KEY_PREFIX,
  DISCOUNT_VALUE_TYPE_CART_FREE_SHIPPING,
} from '@/utils'
import { buildDiscountMessage } from '@/utils/discount'
import { isOnServer } from '@/utils/ssr'
import { useRoute } from 'vue-router'

const useDiscount = () => {
  const route = useRoute()
  const discountStore = useDiscountStore()
  const componentStore = useComponentStore()
  const settingStore = useSettingStore()

  const applyDiscount = async () => {
    // only apply discount on the client side
    if (isOnServer) return
    const ttl = SEVEN_DAYS_IN_MILLISECONDS
    let campaignDiscountCode = route.query[
      QUERY_STRING_CAMPAIGN_DISCOUNT
    ] as string
    let campaign: CampaignDiscount | undefined

    // remove the old discount message
    componentStore.removePromotionBarContent(PROMOTION_BAR_TYPE_DISCOUNT)

    // get campaign discount code from cache first
    const campaginDiscountCodeFromCache = (
      await CacheService.instance?.get(CACHE_KEY_CAMPAIGN_DISCOUNT_CODE)
    )?.value

    // find the campaign corresponding the campaign discount code
    if (campaignDiscountCode) {
      campaign = settingStore.campaigns?.find(
        (elm) => elm.code.toLowerCase() === campaignDiscountCode.toLowerCase()
      )
    }
    if (!campaign && campaginDiscountCodeFromCache) {
      campaign = settingStore.campaigns?.find(
        (elm) =>
          elm.code.toLowerCase() === campaginDiscountCodeFromCache.toLowerCase()
      )
      campaignDiscountCode = campaginDiscountCodeFromCache
    }
    if (campaign) {
      discountStore.campaignDiscountCode = campaignDiscountCode
      discountStore.campaignDiscount = campaign
      discountStore.discountApplications = campaign.values
      discountStore.discountCode = ''
      if (campaign.promotionMessage) {
        componentStore.addPromotionBarContent({
          type: PROMOTION_BAR_TYPE_DISCOUNT,
          priority: PROMOTION_BAR_PRIORITY_MEDIUM,
          value: campaign.promotionMessage,
        })
      }

      CacheService.instance?.set(
        CACHE_KEY_CAMPAIGN_DISCOUNT_CODE,
        campaignDiscountCode,
        ttl
      )
      return
    }

    CacheService.instance?.delete(CACHE_KEY_CAMPAIGN_DISCOUNT_CODE)
    discountStore.campaignDiscountCode = ''
    discountStore.campaignDiscount = null
    discountStore.discountApplications = []

    //  if do not have campaign. Apply discount code
    const discountCode = route.query[QUERY_STRING_DISCOUNT] as string
    const discountCodeFromCache = (
      await CacheService.instance?.get(CACHE_KEY_DISCOUNT_CODE)
    )?.value
    if (!discountCode && !discountCodeFromCache) return 0

    const incomingDiscountParsed = parsedDiscountCode(discountCode)
    const currentDiscountParsed = parsedDiscountCode(discountCodeFromCache)

    let selectedDiscountParsed = incomingDiscountParsed || currentDiscountParsed

    if (
      incomingDiscountParsed &&
      currentDiscountParsed &&
      incomingDiscountParsed.valueType === currentDiscountParsed.valueType
    ) {
      if (currentDiscountParsed.value > incomingDiscountParsed.value) {
        selectedDiscountParsed = currentDiscountParsed
      }
    }
    if (!selectedDiscountParsed) return

    CacheService.instance?.set(
      CACHE_KEY_DISCOUNT_CODE,
      selectedDiscountParsed.code,
      ttl
    )

    discountStore.discountCode = selectedDiscountParsed.code
    discountStore.discountApplications = [selectedDiscountParsed]
    componentStore.addPromotionBarContent({
      type: PROMOTION_BAR_TYPE_DISCOUNT,
      priority: PROMOTION_BAR_PRIORITY_MEDIUM,
      value: selectedDiscountParsed.message as string,
    })
  }

  const parsedDiscountCode = (
    discountCode: string
  ): DiscountApplication | undefined => {
    if (!discountCode) return
    // parse free shipping discount
    if (checkFreeShippingDiscountCode(discountCode)) {
      return {
        code: discountCode,
        conditions: [
          {
            applyFor: DISCOUNT_CONDITION_ALL,
          },
        ],
        value: 0,
        valueType: DISCOUNT_VALUE_TYPE_CART_FREE_SHIPPING,
        message: settingStore.freeShippingMessage,
      }
    }
    // parse value discount
    else if (getValueFromDiscountCode(discountCode) > 0) {
      return {
        code: discountCode,
        conditions: [
          {
            applyFor: DISCOUNT_CONDITION_ALL,
          },
        ],
        value: getValueFromDiscountCode(discountCode),
        valueType: DISCOUNT_VALUE_TYPE_PRODUCT_PERCENTAGE,
        message: buildDiscountMessage(
          DISCOUNT_VALUE_TYPE_PRODUCT_PERCENTAGE,
          getValueFromDiscountCode(discountCode),
          PROMOTION_DISCOUNT_MESSAGE_DEFAULT
        ),
      }
    }
  }

  return { applyDiscount, parsedDiscountCode }
}

function getValueFromDiscountCode(discountCode: string) {
  if (!discountCode) return 0
  let amount = 0
  // check if the discount code is the pattern XXX10_YYY
  if (discountCode.indexOf(DISCOUNT_CODE_DIVIDER) > -1) {
    const firstPart = discountCode.split(DISCOUNT_CODE_DIVIDER)[0]
    if (firstPart.length >= 2) {
      amount = +firstPart.substring(firstPart.length - 2, firstPart.length)
    }
  } else {
    // get the last two number of the discount code as the discount amount
    amount = +discountCode.substring(
      discountCode.length - 2,
      discountCode.length
    )
  }
  if (isNaN(amount)) return 0
  return amount
}

function checkFreeShippingDiscountCode(discountCode: string) {
  if (!discountCode) return
  return discountCode.startsWith(DISCOUNT_FREE_SHIPPING_KEY_PREFIX)
}

export default useDiscount
