<template>
  <header
    class="header app__child"
    :class="{
      container: deviceStore.isMobile,
      'header--fixed': deviceStore.isMobile,
      'header--fixing': deviceStore.isMobile && isMenuFixing,
    }"
    ref="header"
  >
    <nav
      class="header__menu"
      ref="menuContainer"
      :id="menuLogoContainerId"
      :class="{
        container: !deviceStore.isMobile,
        app__child: !deviceStore.isMobile,
      }"
    >
      <Menu :items="menuItems" header="Menu" />
    </nav>
    <div
      class="header__logo-group"
      :class="{
        'header--fixed': !deviceStore.isMobile,
        'header--fixing': !deviceStore.isMobile && isMenuFixing,
        container: !deviceStore.isMobile,
        app__child: !deviceStore.isMobile,
      }"
      ref="logoContainer"
    >
      <Logo
        :class="{ 'flex--centered': deviceStore.isMobile }"
        @click="trackingClickLogo"
      />

      <div class="header__icon-group">
        <Search />

        <Wishlist />

        <CartPopup />

        <Customer class="header__icon-customer hidden-md-down" />
      </div>
    </div>
  </header>
  <!-- header sticker for detecting fixing position of the header on mobile -->
  <div class="header-sticker" ref="headerSticker" :id="headerStickerId"></div>
  <SecondaryMenuHeader v-if="route.name != LANDING_PAGE"></SecondaryMenuHeader>
</template>

<script lang="ts" setup>
import { computed, Ref, ref } from 'vue'
import useSettingStore from '@/store/setting'
import Menu from './Menu.vue'
import Logo from './Logo.vue'
import Search from '@/components/search/Search.vue'
import CartPopup from '@/components/cart/CartPopup.vue'
import Customer from '@/components/account/Customer.vue'
import Wishlist from './Wishlist.vue'
import { onMounted } from 'vue'
import useDeviceStore from '@/store/device'
import {
  EVENT_TRACKING_CLICK_LOGO,
  MIXPANEL_DESTINATION,
  destinationIntegrations,
} from '@/utils'
import Analytics from '@/services/analytics'
import { useRoute } from 'vue-router'
import { useSettings } from '@/composables/settings'
import SecondaryMenuHeader from './SecondaryMenuHeader.vue'

const { filterSettingsByUserSegment } = useSettings()

const menuLogoContainerId = 'header__menu'
const headerStickerId = 'header-sticker'
const route = useRoute()
const settingStore = useSettingStore()
const deviceStore = useDeviceStore()
const menuItems = computed(() =>
  filterSettingsByUserSegment(settingStore.pages?.general?.menu?.items)
)
const logoContainer: Ref<HTMLElement | null> = ref(null)
const menuContainer: Ref<HTMLElement | null> = ref(null)
const headerSticker: Ref<HTMLElement | null> = ref(null)
const header: Ref<HTMLElement | null> = ref(null)
const isMenuFixing = ref(false)
const LANDING_PAGE = 'landing-page'

onMounted(() => {
  if (deviceStore.isMobile) {
    if (!headerSticker.value || !header.value) return
    const options = {
      root: null,
      rootMargin: `-${
        header.value.getBoundingClientRect().height
      }px 0px 0px 0px`,
      threshold: 1,
    }

    const observer = new IntersectionObserver(onMenuIntersect, options)
    observer.observe(headerSticker.value)
  } else {
    if (!menuContainer.value || !logoContainer.value) return
    const options = {
      root: null,
      rootMargin: `-${
        logoContainer.value.getBoundingClientRect().height
      }px 0px 0px 0px`,
      threshold: 1,
    }

    const observer = new IntersectionObserver(onMenuIntersect, options)
    observer.observe(menuContainer.value)
  }
})

function onMenuIntersect(entries: IntersectionObserverEntry[]) {
  entries.forEach((entry) => {
    if (
      entry.target.id === menuLogoContainerId ||
      entry.target.id == headerStickerId
    ) {
      if (entry.isIntersecting) {
        isMenuFixing.value = false
      } else {
        isMenuFixing.value = true
      }
    }
  })
}

function trackingClickLogo() {
  const HEADER_COMPONENT = 'header'
  Analytics.track(
    EVENT_TRACKING_CLICK_LOGO,
    {
      path: route.path,
      component: HEADER_COMPONENT,
    },
    destinationIntegrations([MIXPANEL_DESTINATION])
  )
}
</script>

<style lang="scss">
.header {
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-template-areas: 'a b';
  height: var(--header-height);
  width: 100%;
  background-color: #fff;
  z-index: var(--header-z-index);
  &__menu {
    grid-area: a;
  }
  &__logo-group {
    grid-area: b;
    display: grid;
    grid-template-columns: 4fr 1fr;
    align-items: center;

    img {
      max-width: 175px;
      width: 100%;
    }
  }
  &__icon-group {
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 6px;
  }

  &__icon-customer {
    display: flex;
    text-align: center;
  }

  &--fixed {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba($color: #fff, $alpha: 0.8);
    box-shadow: rgb(222 222 222 / 46%) 0 0 3px 0;
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    transform: translateY(var(--promotion-bar-height));
    transition: transform var(--animation-duration);
  }

  &--fixing {
    box-shadow: rgba(222, 222, 222, 0.46) 0 0 3px 3px;
  }

  @include media-desktop {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      'b'
      'a';

    &__logo-group {
      grid-template-columns: 1fr 2fr;
      width: 100%;
      z-index: var(--header-z-index);
      height: calc(var(--header-height) / 2);
      background-color: rgba($color: #fff, $alpha: 0.8);
      img {
        max-width: unset;
      }
    }
    &__icon-group {
      justify-content: right;
      gap: 24px;
    }

    .search-bar {
      justify-self: center;
    }
  }
}
.header-sticker {
  width: 100%;
  height: 1px;
  background-color: transparent;
}

:root {
  .desktop {
    --header-height: 140px;
  }
}
</style>
