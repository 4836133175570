import {
  CACHE_KEY_USER_LOCATION,
  ONE_DAY_IN_MILLISECONDS,
  TIME_MARK_LOCATION_START,
  MEASURE_NAME_LOCATION_INITIATION,
  GOLDENPHOENIX_IP,
  VN_COUNTRY_CODE,
  GOLDENPHOENIX_IP_DEFAULT,
} from '@/utils'
import Analytics from './analytics'
import CacheService, { getJSONfromCacheResponse } from './cache'
import Http from './http'
import Logger from './log'

export interface UserLocation {
  ip: string
  type: string
  country_code: string
  country_name: string
  region_code: string
  region_name: string
  city: string
  zip: string
  latitude: number
  longitude: number
}

interface LocationService {
  location: UserLocation | null
  restricLocation: boolean
  fetching: boolean
  init: () => Promise<void>
  callbacks: ((location: UserLocation | null) => void)[]
  onLocationReady: (callback: (location: UserLocation | null) => void) => void
  flushCallbacks: () => void
  setRestricLocation: (location: UserLocation | null) => void
}

const LocationService: LocationService = {
  location: null,
  restricLocation: false,
  callbacks: [],
  fetching: false,
  async init() {
    if (this.fetching) return
    this.fetching = true
    try {
      if (!this.location) {
        performance.mark(TIME_MARK_LOCATION_START)
        // get location from cache
        const locationFromCache = await CacheService.instance?.get(
          CACHE_KEY_USER_LOCATION
        )
        if (locationFromCache && locationFromCache.value) {
          this.location = getJSONfromCacheResponse(locationFromCache)
          this.setRestricLocation(getJSONfromCacheResponse(locationFromCache))
        } else {
          const url = `https://api.ipstack.com/check?access_key=ca539347dd90b8a605ef19567a0a5c90&format=1`
          const result = await Http.get(url)
          if (result.success === false) {
            Logger.error('Error when get location', result)
          } else {
            this.location = result
            CacheService.instance?.set(
              CACHE_KEY_USER_LOCATION,
              result,
              ONE_DAY_IN_MILLISECONDS
            )
            this.setRestricLocation(result)
          }
        }
        performance.measure(
          MEASURE_NAME_LOCATION_INITIATION,
          TIME_MARK_LOCATION_START
        )
      }
      Logger.log('User location', this.location)
      this.flushCallbacks()
    } catch (error: any) {
      Logger.error('Error when get location', error)
      Analytics.error(error)
    }
    this.fetching = false
  },
  onLocationReady(callback: (location: UserLocation | null) => void) {
    if (this.location) {
      callback(this.location)
    } else {
      this.init()
      this.callbacks.push(callback)
    }
  },
  flushCallbacks() {
    this.callbacks.forEach((callback) => {
      callback(this.location)
    })
    this.callbacks = []
  },
  setRestricLocation(location: UserLocation | null) {
    if (!location) return
    if (
      location.country_code == VN_COUNTRY_CODE &&
      location.ip != (GOLDENPHOENIX_IP || GOLDENPHOENIX_IP_DEFAULT)
    )
      this.restricLocation = true
  },
}

export default LocationService
