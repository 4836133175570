<template>
  <div class="cart__discount-code">
    <div class="cart__input-discount-code">
      <sib-input
        size="md"
        placeholder="Discount code or gift card"
        v-model="discountCode"
        name="discountCode"
        isShowClearButton
        ref="inputDiscountCode"
      ></sib-input>

      <sib-button
        role="primary"
        width="auto"
        @click="applyDiscountCode"
        :disabled="!discountCode"
        :loading="loading"
      >
        Apply
      </sib-button>
    </div>
    <div class="cart__discount-code-error mt-xs" v-if="errorApplyDiscountCode">
      {{ errorApplyDiscountCode }}
    </div>
    <div class="cart__recently-discount-code mt-sm">
      <template
        v-for="(discountCode, index) in recentlyDiscountCodes"
        :key="index"
      >
        <sib-tag
          size="md"
          round="sm"
          :role="isSelected(discountCode) ? 'primary' : 'default'"
          @click="selectDiscountCode(discountCode, index)"
        >
          <TagIcon />
          {{ discountCode }}

          <div
            v-if="discountTagLoading && index == indexDiscountSelect"
            class="cart__discount-code-loading lds-ring"
          >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div v-else class="cart__remove-discount-code">
            <CloseIcon
              @click.prevent="removeDiscountCode(discountCode, $event)"
            />
          </div>
        </sib-tag>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import SibTag from '@/ui-kits/Tag.vue'
import TagIcon from '@/assets/images/icons/icon-tag.svg'
import CloseIcon from '@/assets/images/icons/icon-close.svg'
import useDiscountStore from '@/store/discount'
import useCheckoutStore from '@/store/checkout'
import useRouteStore from '@/store/route'
import {
  saveDiscountCodeToCache,
  getRecentlyDiscountCodesApplied,
} from '@/utils/discount'
import EventBus from '@/services/eventbus'
import { EVENT_SAVE_DISCOUNT_CODE_TO_CACHE } from '@/utils'

const discountStore = useDiscountStore()
const routeStore = useRouteStore()

const recentlyDiscountCodes = ref<string[]>([])
const discountCode = ref('')
const loading = ref(false)
const discountTagLoading = ref(false)
const indexDiscountSelect = ref(0)
const inputDiscountCode = ref<any>(null)
const errorApplyDiscountCode = ref('')
const checkoutStore = useCheckoutStore()

onMounted(async () => {
  recentlyDiscountCodes.value = await getRecentlyDiscountCodesApplied()
  EventBus.on(EVENT_SAVE_DISCOUNT_CODE_TO_CACHE, async () => {
    recentlyDiscountCodes.value = await getRecentlyDiscountCodesApplied()
  })
})

async function applyDiscountCode() {
  if (!discountCode.value.trim()) return
  loading.value = true
  const respone = await checkoutStore.checkoutDiscountCodeApply(
    discountCode.value.trim()
  )
  if (respone?.checkoutErrors.length && respone.checkoutErrors[0].message) {
    errorApplyDiscountCode.value = respone.checkoutErrors[0].message
    loading.value = false
    return
  }
  errorApplyDiscountCode.value = ''
  discountStore.discountCode = discountCode.value.trim()

  const domain = `.${routeStore.currentDomain}`
  await saveDiscountCodeToCache(discountStore.discountCode, domain)
  recentlyDiscountCodes.value = await getRecentlyDiscountCodesApplied()
  inputDiscountCode.value.clearInput()
  loading.value = false
}

async function selectDiscountCode(discountCode: string, index: number) {
  if (!discountCode || discountCode == discountStore.discountCode) return
  indexDiscountSelect.value = index
  discountTagLoading.value = true

  const respone = await checkoutStore.checkoutDiscountCodeApply(discountCode)
  if (respone?.checkoutErrors.length && respone.checkoutErrors[0].message) {
    errorApplyDiscountCode.value = respone.checkoutErrors[0].message
    discountTagLoading.value = false
    return
  }

  discountStore.discountCode = discountCode
  errorApplyDiscountCode.value = ''
  inputDiscountCode.value.clearInput()
  discountTagLoading.value = false
}

async function removeDiscountCode(discountCode: string, event: Event) {
  if (!discountCode) return
  event.stopPropagation()

  // remove current discount code applied
  if (isSelected(discountCode)) {
    discountTagLoading.value = true
    await checkoutStore.checkoutDiscountCodeRemove()
    discountStore.discountCode = ''
  }

  // remove recently discount code
  const isRemove = true
  const domain = `.${routeStore.currentDomain}`
  await saveDiscountCodeToCache(discountCode, domain, isRemove)
  recentlyDiscountCodes.value = await getRecentlyDiscountCodesApplied()
  discountTagLoading.value = false
}

function isSelected(discountCode: string) {
  const discountCodeCurrentApply = checkoutStore?.checkout?.discountApplications
    .length
    ? checkoutStore.checkout.discountApplications[0].code
    : ''
  return discountCodeCurrentApply.toLowerCase() == discountCode.toLowerCase()
}
</script>

<style lang="scss">
.cart {
  --icon-color: #717171;
  &__input-discount-code {
    display: flex;
    gap: 1em;
  }
  &__recently-discount-code {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  &__discount-code-error {
    font-size: 12px;
    color: var(--color-secondary);
  }
  &__remove-discount-code {
    display: flex;
    path {
      fill: var(--icon-color);
    }
    &:hover {
      path {
        fill: #000;
      }
    }
  }
  &__discount-code-loading {
    font-size: 10px;
    --loading-size: 14px;
    --loading-line-width: 2px;
    div {
      border-color: var(--icon-color) transparent transparent transparent;
    }
  }
  .sib-tag {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5em;
    svg {
      width: 1em;
    }
  }
  .sib-tag--role-default {
    --tag-border-color: var(--controls-background-disabled);
  }
}
</style>
