import { injectScript } from '@/utils/dom'
import { Freshchat, FreshchatUser } from '@/types'
import Analytics from '@/services/analytics'
import {
  EVENT_TRACKING_LIVE_CHAT_ADDED,
  EVENT_TRACKING_LIVE_CHAT_LOADED,
  EVENT_TRACKING_LIVE_CHAT_ERROR,
  MIXPANEL_DESTINATION,
  destinationIntegrations,
} from '@/utils'
import { isOnServer } from '@/utils/ssr'

interface FreshchatService {
  ready: boolean
  readyCallbacks: Array<() => void>
  userCreatedCallbacks: Array<() => void>
  fcUserIdentified: boolean
  init(freshchat: Freshchat): void
  onReady(): void
  excuteOnReady: (callback: () => void) => void
  onUserReady(): void
  excuteOnUserCreated: (callback: () => void) => void
  isUserIdentified(): boolean
  isUserExits(): Promise<void>
  getUser(): Promise<FreshchatUser | undefined>
  identify(user: FreshchatUser): void
  createUser(user: FreshchatUser): Promise<void>
  updateUser(user: FreshchatUser): Promise<void>
  trackPage(url: string, title: string): void
}

const FreshchatService: FreshchatService = {
  ready: false,
  readyCallbacks: [],
  userCreatedCallbacks: [],
  fcUserIdentified: false,
  async init(freshchat: Freshchat) {
    if (!freshchat || isOnServer) return
    injectFreshchatScript(freshchat)
  },
  onReady() {
    if (isOnServer) return
    this.ready = true
    this.readyCallbacks.forEach((callback) => {
      callback()
    })
    this.readyCallbacks = []
  },
  excuteOnReady(callback: () => void) {
    if (isOnServer) return
    if (!this.ready) {
      this.readyCallbacks.push(callback)
      return
    }
    callback()
  },
  onUserReady() {
    if (isOnServer) return
    this.fcUserIdentified = true
    this.userCreatedCallbacks.forEach((callback) => {
      callback()
    })
    this.userCreatedCallbacks = []
  },
  excuteOnUserCreated(callback: () => void) {
    if (isOnServer) return
    if (!this.ready || !this.fcUserIdentified) {
      this.userCreatedCallbacks.push(callback)
      return
    }
    callback()
  },
  isUserIdentified() {
    return this.fcUserIdentified
  },
  async isUserExits() {
    if (isOnServer) return
    if (
      window.fcWidget.user.isExists &&
      typeof window.fcWidget.user.isExists === 'function'
    ) {
      const result = await window.fcWidget.user.isExists()
      this.fcUserIdentified = result.data
    }
  },
  async getUser() {
    if (!this.fcUserIdentified || isOnServer) return
    if (
      window.fcWidget.user.get &&
      typeof window.fcWidget.user.get === 'function'
    ) {
      const result = await window.fcWidget.user.get()
      const fcUser = {
        email: result?.data?.email,
        name: result?.data?.firstName,
      }
      return fcUser
    }
  },
  identify(user: FreshchatUser) {
    if (isOnServer) return
    if (this.fcUserIdentified) {
      this.updateUser(user)
      return
    }
    this.createUser(user)
  },
  async createUser(user: FreshchatUser) {
    if (this.fcUserIdentified || isOnServer) return
    if (
      window.fcWidget.user.create &&
      typeof window.fcWidget.user.create === 'function'
    ) {
      const result = await window.fcWidget.user.create({
        email: user.email,
        firstName: user.name,
      })
      if (result?.data) {
        this.fcUserIdentified = true
      }
    }
  },
  async updateUser(user: FreshchatUser) {
    if (!this.fcUserIdentified || isOnServer) return
    if (
      window.fcWidget.user.update &&
      typeof window.fcWidget.user.update === 'function'
    ) {
      window.fcWidget.user.update({
        email: user.email,
        firstName: user.name,
      })
    }
  },
  trackPage(url: string, title: string) {
    if (!this.fcUserIdentified || isOnServer) return
    if (
      window.fcWidget.trackPage &&
      typeof window.fcWidget.trackPage === 'function'
    ) {
      window.fcWidget.trackPage(url, title)
    }
  },
}

function injectFreshchatScript(freshchat: Freshchat) {
  if (!freshchat) return
  injectScript(
    'https://snippets.freshchat.com/js/fc-pre-chat-form-v2.min.js',
    buildPrechatConfig,
    true
  )

  injectScript(`${freshchat.host}/js/widget.js`, undefined, true)

  Analytics.track(
    EVENT_TRACKING_LIVE_CHAT_ADDED,
    { time: Date.now() },
    destinationIntegrations([MIXPANEL_DESTINATION])
  )

  function buildPrechatConfig() {
    window.prechatTemplate = freshchat.prechatTemplate

    window.fcWidgetMessengerConfig = {
      siteId: freshchat.siteId,
      token: freshchat.token,
      host: freshchat.host,
      widgetUuid: freshchat.widgetUuid,
      config: {
        cssNames: {
          // The below element is mandatory. Please add any custom class or leave the default.
          expanded: 'custom_fc_expanded',
          widget: 'custom_fc_frame',
        },
        agent: {
          hideName: true,
        },
      },
    }

    window.fcSettings = {
      onInit: function () {
        if (
          window.fcPreChatform?.fcWidgetInit &&
          typeof window.fcPreChatform?.fcWidgetInit === 'function'
        ) {
          window.fcPreChatform.fcWidgetInit(window.prechatTemplate)

          Analytics.track(
            EVENT_TRACKING_LIVE_CHAT_LOADED,
            { time: Date.now() },
            destinationIntegrations([MIXPANEL_DESTINATION])
          )
        } else {
          Analytics.track(
            EVENT_TRACKING_LIVE_CHAT_ERROR,
            { time: Date.now() },
            destinationIntegrations([MIXPANEL_DESTINATION])
          )
        }

        window.fcWidget.on('widget:loaded', () => {
          FreshchatService.isUserExits().finally(() => {
            FreshchatService.onReady()
          })

          window.fcWidget.on('user:created', () => {
            FreshchatService.onUserReady()
          })
        })
      },
    }
  }
}

export default FreshchatService
